import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function banner() {
    return (
        <div>
            <div class="uk-section uk-section-large wc">
                <div className="uk-container uk-container-small ">
                    <h1 className="uk-text-uppercase uk-heading-xlarge hero-title  uk-text-right uk-animation-fade">Zdrava<br/>Probava</h1>

                    <div className="uk-margin-medium-top uk-text-center uk-align-center powerd">
                        <p className="uk-text-uppercase ">Powered by</p>
                        <StaticImage
                        src="../images/logo-encian.png"
                        width={150}
                        quality={95}
                        placeholder="tracedSVG"
                        formats={["auto", "webp", "avif"]}
                        alt="Encian logo"
                        style={{ marginBottom: `1.45rem` }}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}
