import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Zdrava probava" />

    <Banner />

    <div className="uk-container uk-container-small uk-margin-medium-top"> 
      <div className="uk-grid-match uk-child-width-1-2@m" data-uk-grid>
          <div>
              <div>
                <StaticImage
                  src="../images/slika-1.jpg"
                  className="uk-border-rounded"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  placeholder="dominantColor"
                  alt="A Gatsby astronaut"
                  style={{ marginBottom: `1.45rem` }}
                />
              </div>
          </div>
          <div>
              <div>
                  <p className="uk-text-large">Gotovo da ne postoji osoba koja se barem jednom u životu nije susrela s probavnim tegobama poput 
zatvora. Zatvor (konstipacija) je česta pojava i u većini slučajeva nije opasna, ali je poprilično 
neugodna. <strong>Svaka druga žena i svaki treći muškarac pate od ovog poremećaja.</strong> Najčešći razlog zatvora je nepravilna prehrana, premali unos vlakana i nedovoljno uzimanje 
tekućine.</p>
              </div> 
          </div>
      </div>    
    </div>

    <div className="wc-2 uk-margin-xlarge-top">
      <div className="uk-container uk-container-small "> 
        <h2 className="uk-heading-large section-title uk-text-uppercase uk-text-right">Simptomi zatvora </h2>
        <p className="uk-text-large">
        Ne postoji jednoznačan odgovor na pitanje koliko često je normalno imati stolicu. Učestalost stolice 
        razlikuje se od osobe do osobe, neki ljudi mogu imati stolicu <strong>tri puta na dan</strong>, dok je kod drugih 
        normalna stolica tri puta tjedno. 
        </p>
        <p className="uk-text-large">Međutim, postoje određeni <strong>simptomi</strong> koji ukazuju <br/>na to da patite od zatvora, a neki od njih su: </p>
      
        <ul className="uk-list uk-list-disc uk-text-large">
          <li>pražnjenje stolice rjeđe od tri puta tjedno </li>
          <li>kvrgava, tvrda ili suha stolica </li>
          <li>naprezanje ili bol tijekom pražnjenja crijeva </li>
          <li>osjećaj sitosti nakon pražnjenja crijeva </li>
        </ul>

      <div className="uk-margin-xlarge-top">
        <StaticImage
          src="../images/INFOGRAFIKA.png"
          className="uk-border-rounded uk-margin-medium"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="A Gatsby astronaut"
        />
      </div>
       
      </div>
    </div>

    <div className="uk-margin-xlarge-top uk-margin-xlarge-bottom"> 
    <div className="wc-3">
    <div className="uk-container uk-container-small uk-text-right "> 
    <div className="uk-margin-medium">

          <div className="uk-inline">

            <StaticImage
            src="../images/packshot.png"
            width={900}
            className=""
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
            />

            <div className="uk-position-top-center badge" data-uk-scrollspy="cls: uk-animation-fade; repeat: true;delay: 800">    
            <StaticImage
              src="../images/badge.png"
              width={170}
              className="uk-border-rounded uk-text-right badge"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `1.45rem` }}
              />
          </div>


            </div>
        
    </div>
      </div>
      </div>
    </div>

    <div className="uk-margin-xlarge-top wc-4">
      <div className="uk-container uk-container-small"> 
        <h2 className="uk-heading-large section-title uk-text-uppercase uk-text-left">Što uzrokuje zatvor?</h2>
        <p className="uk-text-large">Zatvor kod odraslih osoba može imati <strong>više različitih uzroka</strong>, a ponekad ne postoji očiti razlog. U 
nekim slučajevima zatvor može biti uzrokovan onim što konzumiramo, dok se kod određenih ljudi 
zatvor može pojaviti kao <strong>posljedica psihološkog stanja</strong>. U nastavku pročitajte kojih je 5 najčešćih 
uzroka zatvora. 
</p>
        <p className="uk-text-bold uk-text-large">1. Neadekvatna prehrana</p>

        <p className="uk-text-large">Pravilna i raznovrsna prehrana je neophodna za <strong>pravilno funkcioniranje probave</strong>. Osobe koje jedu 
neredovito i čiji se jelovnik uglavnom sastoji od hrane koja je industrijski obrađena, krcata nezdravim 
masnoćama, dodanim šećerom, soli i aditivima, nerijetko pate od zatvora i neredovite probave. Za 
bolju i zdraviju probavu u prehranu je potrebno uvesti više namirnica koje sadrže vlakna, kao što su 
voće, povrće i cjelovite žitarice, a smanjiti unos nezdrave i brze hrane te proizvoda od bijelog brašna.</p>

<p className="uk-text-bold uk-text-large">2. Premali unos tekućine</p>

<p className="uk-text-large">Dehidracija je jedan od najčešćih uzroka kroničnog zatvora. Iako je većina ljudi upoznata s činjenicom 
da je važno piti vodu <strong>više puta tijekom dana</strong>, mnogi jednostavno nemaju naviku redovitog uzimanja 
tekućine. Umjesto toga, posežu za bočicom vode tek onda kada osjete žeđ ili, još gore, žeđ 
pokušavaju utažiti zaslađenim sokovima. To je pogrešno, jer kada nastupi osjećaj žeđi znači da je 
organizam već dehidrirao, a zaslađeni sokovi će uzrokovati još veću žeđ. </p>

<p className="uk-text-large">Voda je potrebna za zdravu probavu jer <strong>olakšava prolazak hrane kroz crijeva</strong> te ih održava glatkima i 
fleksibilnima. Kada u organizmu nema dovoljno vode, debelo crijevo upija vodu iz otpadne hrane, 
zbog čega nastaje tvrda stolica. </p>

<p className="uk-text-large">Dovoljan unos tekućine tijekom dana, konkretno <strong>vode i nezaslađenih čajeva</strong>, održava stolicu mekom 
i olakšava njezino pražnjenje.  </p>

<p className="uk-text-bold uk-text-large">3. Manjak fizičke aktivnosti </p>

<p className="uk-text-large">Redovita fizička aktivnost ima brojne prednosti za organizam, a zdrava probava je jedan od njih. 
Manjak kretanja i sjedilački način života uzrokuju zatvor stolice. Kada se krećemo, gravitacija pomaže 
<strong>kretanju hrane kroz probavni sustav</strong>, dok dugotrajnim sjedenjem ili ležanjem se probava usporava. </p>

<p className="uk-text-large">Aerobne vježbe, koje povećavaju broj otkucaja srca, uzrokuju prirodno kretanje crijeva što čini 
probavu bržom, a pražnjenje stolice lakšom.  </p>

<p className="uk-text-large">Ako niste sportski tip, ne trebate na silu odlaziti u teretanu ili trčati. Bilo koja vrsta tjelovježbe, 
uključujući i sporije aktivnosti poput joge, pomaže održavati zdravlje redovitog sustava. Čak i redovita 
šetnja umjerenog intenziteta može <strong>ublažiti nadutost, poboljšati probavu i olakšati zatvor</strong>.</p>

<h2 className="uk-heading-large section-title uk-text-uppercase uk-text-left uk-margin-large">Ortisan sadrži: </h2>
        <div className="uk-grid-match uk-margin-large-top uk-child-width-1-3@m uk-child-width-1-2 sastojci uk-text-center uk-margin-large-bottom" data-uk-grid data-uk-height-match="target: > div > p">
            <div>
                <div>
                  <StaticImage
                    src="../images/voce/rabarbara.png"
                    className="uk-border-rounded"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                  />
                  <p className="uk-text-uppercase uk-text-bold">rabarbara</p>
                </div>
            </div>
            <div>
                <div>
                  <StaticImage
                    src="../images/voce/kim.png"
                    className="uk-border-rounded"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                  />
                  <p className="uk-text-uppercase uk-text-bold">kim</p>
                </div>
            </div>
            <div>
                <div>
                  <StaticImage
                    src="../images/voce/smokva.png"
                    className="uk-border-rounded"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                  />
                  <p className="uk-text-uppercase uk-text-bold">smokva</p>
                </div>
            </div>
            <div>
                <div>
                  <StaticImage
                    src="../images/voce/tamarind.png"
                    className="uk-border-rounded"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                  />
                </div>
                <p className="uk-text-uppercase uk-text-bold">tamarind</p>
            </div>
            <div>
                <div>
                  <StaticImage
                    src="../images/voce/sljiva.png"
                    className="uk-border-rounded"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                  />
                </div>
                <p className="uk-text-uppercase uk-text-bold">šljiva</p>
            </div>
            <div>
                <div>
                  <StaticImage
                    src="../images/voce/datulje.png"
                    className="uk-border-rounded"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                  />
                  <p className="uk-text-uppercase uk-text-bold">datulje</p>
                </div>
            </div>
        </div>

<p className="uk-text-bold uk-text-large">4. Stres </p>

<p className="uk-text-large">Stručnjaci upozoravaju da su probavne smetnje često <strong>posljedica visoke razine stresa</strong>. Gotovo da ne 
postoji osoba koja se ne susreće sa stresom u svom životu, no kada je stres pretjeran može dovesti 
do problema s probavom, kao što je neredovito pražnjenje crijeva. </p>

<p className="uk-text-large">Poznato je da živčani sustav upravlja probavnim sustavom, a visoka razina stresa može poremetiti 
uravnoteženost živčanog sustava i samim time <strong>narušiti funkcioniranje probave</strong>. Također, osobe koje 
su pod stresom mogu osjetiti problem s manjkom sna, što je savršen recept za nastanak zatvora. </p>

<p className="uk-text-bold uk-text-large">5. Putovanja</p>

<p className="uk-text-large">Putovanja čak i ona poslovna, su poprilično uzbudljiva. Međutim, promjena okoline i rutine mogu izazvati problem sa zatvorom. Većina ljudi ima svoju rutinu koja uključuje i odlazak na toalet, a kada nekoliko dana nisu kod kuće, mogu doživjeti <strong>probavne probleme </strong>. Osim toga, na putovanjima 
nerijetko imamo poremećen bioritam, pogotovo ako je prisutna vremenska razlika.</p>

<div className="uk-margin-xlarge-top">
      <div className="uk-container uk-container-small"> 
        <h2 className="uk-heading-large section-title uk-text-uppercase uk-text-left">Kada se javiti liječniku? </h2>


<p className="uk-text-large">U rjeđim slučajevima, zatvor može biti uzrokovan zdravstvenim stanjem. Patite li često od zatvora, 
postoji mogućnost da imate problema s radom štitnjače, jer nepravilna <strong>razina hormona štitnjače </strong>
može usporiti probavni sustav. Ako je zatvor dugotrajan i popraćen bolovima, možda patite od 
<strong>sindroma iritabilnog crijeva</strong>. To je čest poremećaj koji može uzrokovati zatvor ili proljev. </p>

<p className="uk-text-large">U svakom slučaju, obavezno se javite svome <strong>liječniku</strong> ako primijetite jedan ili više sljedećih 
simptoma: </p>


<ul className="uk-list uk-list-disc uk-text-large">
          <li>Krvarenje iz rektuma</li>
          <li>Krv u stolici</li>
          <li>Dugotrajna bol u abdomenu </li>
          <li>Bol u donjem dijelu leđa </li>
          <li>Neobjašnjivo povraćanje </li>
          <li>Groznica </li>
          <li>Neobjašnjiv gubitak težine </li>
          <li>Nagla promjena redovitosti pražnjenja crijeva </li>
        </ul>

        <p className="uk-text-large">U takvim će vas slučajevima liječnik poslati na preglede koji će potvrditi ozbiljnije stanje. Ako 
specijalistički pregledi isključe mogućnost da je riječ o određenoj bolesti ili stanju, tada ćete morati 
<strong>promijeniti svoje navike</strong> i izbaciti namirnice koje ne odgovaraju vašem probavnom sustavu. </p>

<StaticImage
  src="../images/slika-2.jpg"
  className="uk-border-rounded uk-margin-medium"
  quality={95}
  formats={["auto", "webp", "avif"]}
  alt="A Gatsby astronaut"
/>

<p className="uk-text-bold uk-text-large">Namirnice koje je potrebno izbjegavati kod zatvora </p>

<p className="uk-text-large">Određene namirnice su teže probavljive, ne sadrže dovoljno hranjivih tvari i vlakana te dehidriraju organizam. Čestom konzumacijom takvih namirnica najčešće se javlja konstipacija, odnosno zatvor. Takve je namirnice najbolje u potpunosti izbjegavati i zamijeniti ih odgovarajućim alternativama. </p>

<p className="uk-text-bold uk-text-large">1. Alkohol </p>

<p className="uk-text-large">Alkohol, pogotovo kada se konzumira u pretjeranim količinama, može povećati količinu izgubljene 
tekućine kroz urin i <strong>uzrokovati dehidraciju</strong>. Kao što je poznato, dehidracija je povezana s povećanim 
rizikom za nastanak zatvora. Ipak, učinci alkohola se razlikuju od osobe do osobe. Neki ljudi nakon 
konzumacije alkohola imaju problem s proljevom, a ne sa zatvorom. </p>

<div className="uk-box-shadow-xlarge uk-box-shadow-hover-medium uk-padding uk-border-rounded uk-margin-medium" uk-scrollspy="cls:uk-animation-fade">
<p className="uk-text-large"><strong>SAVJET:</strong> Iako je alkohol najbolje u potpunosti izbjegavati, u određenim je situacijama teško ne popiti 
koju čašicu. Zato se preporučuje nakon svake čaše alkoholnog pića popiti čašu vode. </p>
</div>

<p className="uk-text-bold uk-text-large">1. Prerađene žitarice </p>

<p className="uk-text-large">Proizvodi od prerađenih žitarica, kao što su bijeli kruh i tjestenina od bijelog brašna, nemaju dovoljno 
hranjivih tvari zbog čega <strong>povećavaju razinu šećera u krvi</strong> te uzrokuju suhu i tvrdu stolicu. Jedan od 
glavnih razloga je taj što se prilikom obrade žitarica uklanjaju mekinje, a mekinje sadrže vlakna koja 
pogoduju pravilnoj probavi.</p>

<div className="uk-box-shadow-xlarge uk-box-shadow-hover-medium uk-padding uk-border-rounded uk-margin-medium" uk-scrollspy="cls:uk-animation-fade">
<p className="uk-text-large"><strong>SAVJET:</strong> U slučaju da vaša prehrana sadrži puno proizvoda od bijelog brašna, pokušajte postupno 
smanjiti unos prerađenih žitarica i zamijeniti ih proizvodima od cjelovitog zrna.</p>
</div>

<p className="uk-text-bold uk-text-large">3. Mlijeko i mliječni proizvodi</p>

<p className="uk-text-large">Kod nekih ljudi mlijeko i mliječni proizvodi mogu biti uzrok zatvora. Zbog toga osobe često 
posumnjaju da je riječ o intoleranciji na laktozu. Međutim, <strong>intolerancija na laktozu</strong> uzrokuje zatvor, a 
ne proljev. Kod osoba koje nakon konzumacije mliječnih proizvoda osjećaju zatvor, postoji mogućnost 
da su osjetljivi na proteine koji se nalaze u kravljem mlijeku.  
</p>

<div className="uk-box-shadow-xlarge uk-box-shadow-hover-medium uk-padding uk-border-rounded uk-margin-medium" uk-scrollspy="cls:uk-animation-fade">
<p className="uk-text-large"><strong>SAVJET:</strong>  Ako vam mliječni proizvodi uzrokuju probavne smetnje, a ne želite ih u potpunosti ukloniti iz 
svoje prehrane, tada birajte fermentirane proizvode poput jogurta i kefira. Takvi proizvodi sadrže 
dobre bakterije koje pogoduju probavnom sustavu.  </p>
</div>


<p className="uk-text-bold uk-text-large">4. Crveno meso</p>

<p className="uk-text-large">Crveno meso, za razliku od mesa peradi i ribe, sadrži veće količine masti i premalo vlakana zbog čega 
je organizmu potrebno dulje vrijeme da ga probavi. U većini slučajeva to može povećati vjerojatnost 
za nastanak zatvora. Osim toga, crveno meso sadrži i <strong>velike količine željeza</strong>, zbog čega se 
preporučuje konzumirati ga u ograničenim količinama. </p>

<div className="uk-box-shadow-xlarge uk-box-shadow-hover-medium uk-padding uk-border-rounded uk-margin-medium" uk-scrollspy="cls:uk-animation-fade">
<p className="uk-text-large"><strong>SAVJET:</strong> Kako biste olakšali rad svojim crijeva, ograničite unos crvenog mesa. Ako ćete konzumirati 
crveno meso, onda uz njega jedite prilog koji se sastoji od namirnica koje su bogate vlaknima, kao što su grah, slanutak, prokulice ili cikla.</p>
</div>

<p className="uk-text-bold uk-text-large">5. Pržena hrana </p>

<p className="uk-text-large">Pržena hrana obično sadrži puno masti i malo vlakana, zbog čega se teško probavlja i uzrokuje 
problem s pražnjenjem stolice. Također, pržena i brza hrana sadrže <strong>velike količine soli</strong>. Unosom 
pretjerane količine soli, organizam crpi vodu iz crijeva kako bi se koncentracija soli u krvotoku vratila 
u normalu. Taj manjak vode u crijevima uzrokuje tvrdu stolicu, otežava njezin prolazak kroz tijelo i 
uzrokuje zatvor.</p>

<div className="uk-box-shadow-xlarge uk-box-shadow-hover-medium uk-padding uk-border-rounded uk-margin-medium" uk-scrollspy="cls:uk-animation-fade">
  <p className="uk-text-large"><strong>SAVJET:</strong>  Umjesto grickalica, poput čipsa, radije konzumirajte kokice koje sami možete pripremiti bez 
  prevelike količine soli. Brzu hranu, poput hamburgera, radije pripremajte kod kuće uz ograničenu 
  količinu masnoće. </p>
</div>

</div>
</div>

<div className="uk-margin-xlarge-top">
      <div className="uk-container uk-container-small"> 
        <h2 className="uk-heading-large section-title uk-text-uppercase uk-text-right">Što učiniti kod zatvora? </h2>

        <p className="uk-text-large">Iako je zatvor neugodna pojava koja može otežati svakodnevno funkcioniranje, u većini slučajeva ne 
predstavlja opasan problem. Rješenja za većinu probavnih smetnji su jednostavna: jedite 
uravnoteženo i zdravo, pijte puno vode i unosite vlakna, topiva i netopiva. Također, uzmite u obzir i 
proizvode koji imaju <strong>prirodno laksativno djelovanje i smanjuju nadutost</strong>, kao što su <a target="_blank" href="https://encian.hr/ortisan-forte-vocne-kocke-120g-proizvod-714/">Ortisan Forte Voćne Kocke</a> i novost na tržištu - <a target="_blank" href="https://encian.hr/ortisan-forte-tablete-12kom-proizvod-957/">Ortisan Forte tablete</a>. 
</p>

<p className="uk-text-large">Voćne Kocke i novost na tržištu - Ortisan Forte tablete.  
Ortisan Forte Voćne Kocke i Ortisan Forte tablete sadrže prirodne sastojke koji imaju višestruko 
djelovanje: 
</p>

​<ul className="uk-list uk-list-disc uk-text-large">
  <li>rabarbara pomaže u olakšavanju pražnjenja crijeva </li>
  <li>smokve doprinose normalnoj funkciji crijeva </li>
  <li>tamarind potiče pražnjenje crijeva</li>
  <li>kim pomaže smanjenju nadutosti </li>
</ul>


<StaticImage
  src="../images/packshot_2.png"
  className="uk-border-rounded uk-margin-medium"
  quality={95}
  formats={["auto", "webp", "avif"]}
  alt="A Gatsby astronaut"
/>


      </div>
</div>

          
      </div>
    </div>



  </Layout>
)

export default IndexPage
